@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsRegular.ttf");
  font-family: "Poppins";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsMedium.ttf");
  font-family: "Poppins";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsLight.ttf");
  font-family: "Poppins";
  font-weight: 300;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ArchivoRomanSemiBold.ttf");
  font-family: "Archivo";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsSemiBold.ttf");
  font-family: "Poppins";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterMedium.ttf");
  font-family: "Inter";
  font-weight: 500;
}
